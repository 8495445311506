<template>
    <div>
        <newHeader url="/JinrongIndex"></newHeader>
        <div class="title-box-1">
            FINANCIAL PRODUCTS
        </div>
        <div class="title-box-2">
            <img src="../assets/images/jinrongTitle.png" height="31" width="123" class="img-title"/>
            <div class="img-title">
            </div>
        </div>
        <div class="financial-container">
            <div class="box">
                <div class="box-img-box">
                    <img src="../assets/images/icon/car.png"/>
                </div>
                <nav>车辆保险服务</nav>
                <img src="../assets/images/wave.png" class="img-bottom"/></div>
            <div class="box">
                <div class="box-img-box">
                    <img src="../assets/images/icon/cash.png" height="110" width="110"/>
                </div>
                <nav>金融咨询服务</nav>
                <img src="../assets/images/wave.png" class="img-bottom"/></div>
            <div class="box">
                <div class="box-img-box">
                    <img src="../assets/images/icon/give.png" height="110" width="110"/>
                </div>
                <nav>运费、保证金垫付申请</nav>
                <img src="../assets/images/wave.png" class="img-bottom"/></div>
        </div>
        <buttom></buttom>
    </div>
</template>

<script>
    import newHeader from "@/components/common/newHeader";
    import Buttom from "@/components/common/Buttom";
    import Swipper from "@/components/common/index/Swipper";
    import FlowServiceTitle from "@/components/common/index/FlowServiceTitle";

    export default {
        name: "JinrongIndex",
        data: function () {
            return {
                title: '金融产品',
                content: '为您提供更多元的金融服务',
                currentDate: new Date(),
            }
        },
        components: {
            newHeader,
            Buttom,
            Swipper,
            FlowServiceTitle,
        },
    }
</script>
<style lang="stylus" scoped>
    .box-img-box {
        width 100%
        text-align center
        padding-top: 60px;
    }

    .box:hover {
		background-image: linear-gradient(to bottom right,#1a4fcd, #6deee6);
		.img-bottom {
			background-image: linear-gradient(to bottom right,#1a4fcd, #6deee6);
			display none
		}
    }

    .box {
        position relative
        width 380px
        height 270px
        display inline-block
        box-sizing border-box
        background #ffffff
        border 1px solid #cecece
        margin 15px

        nav {
            font-size 14px
            color #333
        }

        .img-bottom {
            width 100%;
            position absolute;
            bottom 0;
            left 0;
        }
    }

    .financial-container {
        background #f1f1f1
        height 438px
        padding 69px 0
        text-align center
    }

    .title-box-1 {
        height 200px;
        font-size 52px;
        color #f5f7fc;
        line-height 200px;
        z-index -999;
        position absolute;
        width 100%;
        letter-spacing 5px
        text-align center
    }

    .title-box-2 {
        padding 100px 0
        text-align center
    }

    .img-title:after {
        content '';
        width 50px;
        height 1px;
        display block;
        margin 0 auto
        border-bottom 3px solid #1a4fcd;
    }

</style>
